import React, { useEffect } from 'react'
import appStoreLogo from './apple-app-store-badge-min.png'
import playStoreLogo from './google-play-store-badge-min.png'
import appLogo from './ic_launcher_round-playstore-min.png'
import './css/factmazing.css'

const RedirectPage = () => {
  const appStoreLink = {
    apple: 'https://apps.apple.com/us/app/til-facts-did-you-know/id6444804849',
    google:
      'https://play.google.com/store/apps/details?id=com.sumeetlubal.factmazing'
  }

  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent.toLowerCase()
    if (/(android)/.test(userAgent)) {
      console.log('Redirect android...')
      window.location.href = appStoreLink.google
      return
    } else if (/(iphone|ipad|ipod)/.test(userAgent)) {
      console.log('Redirect ios...')
      window.location.href = appStoreLink.apple
      return
    } else {
      console.log('Other OS')
    }
  }

  return (
    <div className='box'>
      <div className='stage'>
        <div>
          <img className='app-logo' src={appLogo} />
        </div>
        <p className='title_font'>TIL Facts App</p>
        <p className='body_font'>
          🌟 Dive into our vast collection of 10,000 facts! 🎉{' '}
        </p>
        <p className='body_font'>
          Different categories with fascinating facts all bundled up at your
          fingertips. 📚 🛰️{' '}
        </p>
        <p className='body_font'>
          ✨ Experience the joy of learning something new every day! ✨{' '}
        </p>
        <div className='app-links'>
          <a href={appStoreLink.apple} className='app-store-link'>
            <img
              src={appStoreLogo}
              alt='Download on the App Store'
              className='store_img'
            />
          </a>
          <a href={appStoreLink.google} className='app-store-link'>
            <img
              src={playStoreLogo}
              alt='Get it on Google Play'
              className='store_img'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default RedirectPage
